.location-info {
    display: flex;
    margin-bottom: 24px;

    .text_default {
        color: $dark-gray-color;
        margin-top: 12px;
    }
    .text_secondary {
        color: $main-blue-color;
    }
}
.location-info__icon,
.location-info__icon_big {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
}
.location-info__icon_big {
    width: 65px;
    height: 65px;
}
.location-info__icon_info {
    background-image: url("../../../../static/images/i-sign-gray.svg");
    min-width: 17px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
}