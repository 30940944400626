.favorite-locations-page__container {
    padding: 20px;
    text-align: center;
}
.favorite-locations-page__container .container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.report-it-logo {
    margin-bottom: 16px;
}