.messages-page__container {
    display: flex;
    align-items: center;

    .text_default {
        max-width: 280px;
        text-align: center;
        margin-bottom: 5vh;
    }
    .text_block {
      display: block !important;
    }
    .button {
        max-width: 160px;
        margin-top: 5vh;
    }

    .human-id__button {
        margin: 5vh 0 5vh 0;
    }
}
