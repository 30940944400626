.header,
.header__theme_light {
    background: $bright-blue;
    display: flex;
    align-items: center;
    padding: 24px 17px;
    justify-content: space-between;
}
.header__theme_light {
    background: $white-color;
    padding-bottom: 0;
}
.header__icon_back,
.header__icon_back__theme_dark {
    background-image: url("../../../static/images/back-icon-white.svg");
    width: 9px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.header__icon_back__theme_dark {
    background-image: url("../../../static/images/back-icon-blue.svg");
}
.header__icon_home {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/Home\ icon.svg");
    cursor: pointer;
}
.header__title {
    white-space: pre-wrap;
    margin: auto;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.41px;
    color: $white-color;
}
.header__steps_current {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: $white-color;
}
.header__steps_max {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: $white-color;
}
.header__title_cancel {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: $white-color;
    cursor: pointer;
}
.header__logo,
.header__logo_centered,
.header__logo__theme_light {
    width: 120px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/Logo.svg");
    cursor: pointer;
}
.header__logo_centered {
    flex: 1;
}
.header__logo__theme_light {
    background-image: url("../../../static/images/logo-white.svg");
}
.header__container {
    display: flex;
    align-items: center;
    margin: 50px 16px 0;
    justify-content: space-between;
}
.header__icon_language {
    width: 25px;
    cursor: pointer;
    margin-left: 10px;
}
