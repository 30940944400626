.non-reporting-location__text {
    .text_default {
        display: block;
    }
    .text_bold {
        font-size: 14px;
    }
    .text_default:first-child {
        margin-bottom: 12px;
    }
}
.non-reporting-location__divider {
    background: #e0e7f0;
    height: 1px;
    width: 100%;
    margin: 20px auto;
}
.contact-details {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
}
.contact-details__text {
    .text_default {
        color: $dark-gray-color;
    }
    .text_primary {
        color: $bright-blue;
    }
    &.text_primary {
        color: $bright-blue;
    }
}
.contact-details__icon_non-emergency,
.contact-details__icon_tip-line,
.contact-details__icon_email,
.contact-details__icon_website,
.contact-details__icon_tip {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-top: 4px;
}
.contact-details__icon_non-emergency {
    background-image: url("../../../../static/images/contact-details/phone.svg");
}
.contact-details__icon_tip-line {
    background-image: url("../../../../static/images/contact-details/phone.svg");
}
.contact-details__icon_email {
    background-image: url("../../../../static/images/contact-details/email.svg");
}
.contact-details__icon_website {
    background-image: url("../../../../static/images/contact-details/website.svg");
}
.contact-details__icon_tip {
    background-image: url("../../../../static/images/contact-details/tip.svg");
}
.contact-details__icon_linkedin,
.contact-details__icon_twitter,
.contact-details__icon_facebook {
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 16px;
}
.contact-details__icon_linkedin {
    background-image: url("../../../../static/images/contact-details/linkedin.svg");
}
.contact-details__icon_twitter {
    background-image: url("../../../../static/images/contact-details/twitter.svg");
}
.contact-details__icon_facebook {
    background-image: url("../../../../static/images/contact-details/facebook.svg");
}
.contact-details__text_first {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: $bright-blue;
}
.contact-details__text_second {
    font-weight: normal;
    font-size: 12px;
    line-height: 113%;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: $dark-gray-color;
}
.contact-details__social-networks {
    display: flex;
    min-height: 50px;
}
