.local-media-preview__container, 
.local-media-preview__controls, 
.local-media-preview__control,
.local-media-preview__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-preview__container, 
.local-media-preview__text, 
.local-media-preview__controls,
.local-media-preview__container .section__header,
.local-media-preview__container .report-it-logo {
    margin-bottom: 8px;
}

.local-media-preview__container {
    flex-direction: column;
}

.local-media-preview__container {
    padding: 16px;
    max-width: 600px !important;
    text-align: center;
}

.local-media-preview__container .section__header {
    margin-top: 0;
}

.local-media-preview__container .media-preview__container,
.local-media-preview__container video {
    width: 100%;
}

.local-media-preview__container video {
    max-height: 60vh;
    object-fit: cover;
}

.local-media-preview__controls {
    justify-content: space-around;
}

.local-media-preview__controls_icon {
    width: 32px;
    height: 32px;
}

.local-media-preview__control {
    margin-right: 32px;
    cursor: pointer;
    user-select: none;
}

.local-media-preview__control:hover {
    opacity: 0.7;
}

.local-media-preview__control.disabled {
    cursor: default;
    opacity: 0.5;
}

.local-media-preview__navigation {
    width: 100%;
    justify-content: space-between;
}

.local-media-preview__navigation_button {
    width: 45%;
    padding: 8px 0;
    color: $white-color;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
}

.local-media-preview__navigation .join-button {
    background: $bright-blue;
}

.local-media-preview__navigation .cancel-button {
    background: $bright-red;
}

@media (max-width: 480px) { 
    .local-media-preview__container video {
        max-height: 40vh;
    }
}