#video-chat__container {
    height: 100vh;
    position: relative;
    display: flex; 
    flex-direction: column;
    max-width: 100% !important;
}

.video-chat__media {
    width: 100%;
    background: $white-color;
    z-index: 100;
}

.video-chat__participants-media {
    position: relative;
    width: 100%;
    height: 60vh;
    max-width: 100% !important;
    margin-bottom: 8px;
}

.video-chat__background {
    position: absolute;
    margin-left: auto !important;
    margin-right: auto !important;
    top: 45%;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
    z-index: -1;
}

.video-chat__background-header {
    margin: 0;
}

.video-chat__background .report-it-logo {
    margin: 8px;
}

.video-chat__user-media {
    position: absolute;
    left: 5%;
    bottom: 5%;
    max-width: 20% !important;
    text-align: center;
}

.video-chat__user-media video,
.video-chat__participants-media video {
    width: 100%;
    max-width: 100% !important;
    max-height: 60vh;
    object-fit: cover;
}

.video-chat__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.video-chat__control {
    cursor: pointer;
    user-select: none;
}

.video-chat__control:hover {
    opacity: 0.7;
}

.video-chat__control.disabled {
    cursor: default;
    opacity: 0.5;
}

.video-chat__control_icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
}

.video-chat__message-chat {
    position: relative;
    bottom: 0;
    height: calc(40vh - 72px);
    padding: 8px;
    border: 2px solid #c1c1c1;
    border-radius:0 0 8px 8px;
    margin-bottom: 16px;
}

.video-chat__message-chat .chat__container {
    max-height: calc(100% - 72px);
    padding-bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: auto;
}    

.video-chat__message-chat .chat__input__container {
    position: absolute !important;
    bottom: 0;
}

@media(max-width: 800px) {
    #video-chat__container {
        flex-direction: column;
    }

    .video-chat__media-container,
    .video-chat__sidebar-container {
        width: 100%;
        margin: 0 0 16px 0;
    }
    
    .video-chat__participants-media {
        height: 50vh;
    }

    .video-chat__user-media video,
    .video-chat__participants-media video {
        max-height: 50vh;
    }
    
    .video-chat__message-chat {
        height: calc(50vh - 72px);
    }
}

@media (max-width: 678px) {
    .video-chat__message-chat {
        border: 0;
        border-radius: 0;
        border-top: 2px solid #c1c1c1;
    }

    .video-chat__message-chat .chat__input__container {
        position: fixed !important;
    }

    .video-chat__message-chat .chat__container {
        padding-bottom: 72px;
    }
}



@media (max-width: 425px) {
    .video-chat__participants-media {
        height: 30vh;
    }

    .video-chat__user-media video,
    .video-chat__participants-media video {
        max-height: 30vh;
    }
    
    .video-chat__message-chat {
        height: calc(70vh - 72px);
    }
}

@media (min-height: 1000px) and (min-width: 800px) {
    .video-chat__participants-media {
        height: 30vh;
    }

    .video-chat__user-media video,
    .video-chat__participants-media video {
        max-height: 30vh;
    }
    
    .video-chat__message-chat {
        height: calc(70vh - 72px);
    }
}