.report-navigation {
    padding: 30px 16px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 12;
    position: fixed;
    background: $white-color;
    box-sizing: border-box;
    margin: auto;
}

.report-navigation__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button_bordered {
        height: 40px;
    }

    .button_bordered:first-child {
        margin-right: 16px;
    }
}
.panic-alarm {
    margin-bottom: 12px;
}

.short-form_cancel-button {
    border: 1px solid $bright-red;
    color: $bright-red;
}

.short-form_submit-button {
    background: $bright-green;
    border: 1px solid $bright-green;
    color: $white-color;
}

.short-form_submit-button-disabled {
    opacity: 0.4 !important;
    cursor: default !important;
}