.category-header {
    background: $white-color;
    box-shadow: 0px 0px 30px rgba(37, 36, 91, 0.1);
    border-radius: 10px;
    height: 40px;
    margin-top: 32px;
    display: flex;
    align-items: center;
}
.category-header__icon {
    background-image: url("../../../static/images/back-icon-blue.svg");
    width: 9px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 16px;
}
.view-selector {
    background: $white-color;
    box-shadow: 0px 0px 30px rgba(37, 36, 91, 0.1);
    border-radius: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.084px;
    color: $white-color;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 12px;
}
.view-selector__item,
.view-selector__item_active {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.084px;
    color: $main-blue-color;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.view-selector__item_active {
    background: $bright-blue;
    border-radius: 8px;
    color: $white-color;
}
.location-list__header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9e9ef;

    .text_default {
        letter-spacing: -0.03em;
        text-transform: uppercase;
    }
}
.location-list__header__icon,
.location-list__header__icon_non-reporting {
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
    border-radius: 50%;
}
.location-list__header__icon {
    min-width: 20px;
    min-height: 20px;
    background-image: url("../../../static/images/report-icon.svg");
}
.location-list__header__icon_non-reporting {
    width: 16px;
    height: 16px;
    background-image: url("../../../static/images/i-sign-dark-blue.svg");
}
.map-view,
.map-view_opened {
    background: #fbfbfb;
    border-radius: 20px 20px 0 0;
    padding-top: 11px;
    padding-bottom: 0;
    overflow: hidden;
    max-height: 75px;
    position: absolute;
    z-index: 5;
    bottom: 60px;
    left: 0;
    right: 0;
}
.map-view_opened {
    max-height: 40vh;
    overflow: auto;

    .change-area__container {
        margin-top: 11px;
    }
    .location-list__header {
        margin-top: 10px;
    }
}
.button_toggle {
    background: #dadada;
    border-radius: 5px;
    width: 30px;
    min-height: 3px;
    height: 0.6vh;
    margin: auto;
    cursor: pointer;
}
