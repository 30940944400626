.chat__container {
    overflow: auto;
    max-height: calc(100vh - 180px);
    padding-bottom: 72px;
    box-sizing: border-box;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}
.message__item {
    margin-bottom: 20px;
}
.message__time {
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: $dark-gray-color;
    justify-content: center;
    margin-bottom: 12px;
}
.message__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $main-blue-color;
    background: $light-gray-color;
    border-radius: 5px 5px 5px 0px;
    padding: 12px;
    word-break: break-word;
}
.message__link {
    font-size: 14px;
}
.message__location-logo {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    align-self: flex-end;
    background-color: $dark-gray-color;
}
.message__wrapper_location {
    display: flex;
    max-width: 90%;
}
.message__wrapper_user {
    max-width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    .message__text {
        background: $bright-blue;
        color: $white-color;
        border-radius: 5px 5px 0px;
    }
}
.chat__input__container {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white-color;
    padding: 36px 16px;
    margin: 0;
}
.chat__input {
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    flex: 1;
    padding: 9px 16px;
    box-shadow: 0px 0px 30px rgba(37, 36, 91, 0.1);
    border-radius: 10px 0 0 10px;

    &::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: $dark-gray-color;
    }
}
.chat__button {
    background: $bright-blue;
    border-radius: 10px;
    width: 80px;
    height: 40px;
    color: $white-color;
    align-items: center;
    justify-content: center;
    display: flex;
}
