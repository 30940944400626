.section__header.short-form {
    margin-bottom: 20px;
}
.sublocation-picker__button__container {
    justify-content: space-around !important;
    padding: 0 !important;
    border: 0 !important;
    border-top: 1px solid #acacac !important;
}
.sublocation-picker__title {
    border-bottom: 1px solid #acacac !important;
}
.sublocation-picker__title,
.sublocation-picker__items {
    padding: 5% 0 !important;
}