.report-succeeded__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 2vh 16px 0;
    box-sizing: border-box;
    overflow: auto;

    .text_default,
    .text_default_gray {
        text-align: center;
        justify-content: center;
        display: block;
    }
    a.link {
        display: inline;
        cursor: pointer;
    }

    .google-play_link,
    .app-store_link {
        margin: 1vh;
    }

    .html-markup__container {
        padding: 0;
    }

    .report-succeeded__thank-you-text {
        display: block !important;
        font-size: 16px !important;
    }
}

.report-succeeded__container .main,
.report-succeeded__navigation-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;

    > div,
    .report-succeeded__messages-button,
    .report-succeeded__home-button {
        margin-bottom: 2vh;
    }

    .creds {
        .text_enlarged {
            justify-content: center;
            white-space: pre;
        }
    }
}

.report-succeeded__logo {
    min-width: 159px;
    min-height: 46px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../static/images/Logo.svg");
    background-size: contain;
}

.report-succeeded__success-icon {
    min-width: 62px;
    min-height: 62px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../static/images/success-check-icon.svg");
    margin: 2vh auto;
    background-size: contain;
}

.report-succeeded__download-apps-icons__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}