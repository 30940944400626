.media-control__placeholder__icon {
    background-image: url("../../../../../static/images/upload.svg");
    width: 20px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
}
.media-control__placeholder__text {
    display: flex;
    white-space: pre;

    .text_default {
        color: $bright-blue;
    }
}
.media-control__placeholder {
    margin-top: 16px;
    display: flex;
    align-items: center;
    background: $light-gray-color;
    border-radius: 10px;
    min-height: 40px;
    padding: 10px 16px;
    box-sizing: border-box;
}
.media-control__placeholder_disabled {
    opacity: 0.4;
}
#media-control__input {
    opacity: 0;
    position: absolute;
    z-index: -10;
}
.preview__item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .text_default {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 235px;
    }
}
.preview__thumbnail {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    opacity: 0.4;
    border-radius: 10px;
}
.preview__icon_remove {
    background-image: url("../../../../../static/images/remove.svg");
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
}
.preview__thumbnail_default {
    background-image: url("../../../../../static/images/preview-default.svg");
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
