.terms__container {
    padding-bottom: 0;
    overflow: auto;

    .text_primary {
        margin-bottom: 10px;
    }
    .text_primary:last-child {
        margin: 0;
    }
}
