.date-time-control {
    .text_default {
        flex: 1;
    }
}
.date-time-control__icon_date {
    background-image: url("../../../../../static/images/date-icon.svg");
    min-width: 24px;
    min-height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px 17px;
}
.date-time-control__icon_time {
    background-image: url("../../../../../static/images/time-icon.svg");
    min-width: 24px;
    min-height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px 17px;
}
