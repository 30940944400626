.report__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;

    .section__header {
        margin-bottom: 24px;
        margin-top: 24px;
    }
    .toggle-switch {
        margin-right: 12px;
    }
    .toggle-switch__section {
        display: flex;
        margin: 24px 0 20px;
    }
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    max-width: unset !important;
}
.report__map {
    width: 100%;
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: 200px;
}
.google-captcha__container {
    color: $dark-gray-color;
    font-size: 10px;
}
.google-captcha__link {
    display: inline;
    font-size: 10px;
}
