.category__header {
    width: 100%;
    height: 220px;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../../static/images/illustartion.svg");
}
.category__icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    height: 16px;
    width: 16px;
    margin: 0 12px;
}
.category__item {
    background: $light-gray-color;
    border-radius: 10px;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}
.category_hidden {
    display: none;
}
