.interactive-input__container {
    display: flex;
    margin: 16px 0 25px;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.interactive-input {
    max-width: 28px;
    border-bottom: 1px solid #b0b3cc;
    background-color: $white-color;
    margin-right: 7px;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    color: $main-blue-color;
    height: 40px;
    padding: 0;
}
