.use-current-location__icon {
    width: 13px;
    height: 13px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/change-area/use-current.svg");
    margin-right: 6px;
}
.use-current-location__text {
    color: $bright-blue;
    cursor: pointer;
}
.use-current-location__container {
    display: flex;
    align-items: center;
    margin: 24px 0;
}
.pac-container {
    &.change-area {
        position: absolute !important;
        width: 100% !important;
        left: unset !important;
        top: 180px !important;
        border: none;
        box-shadow: none;
        padding: 0 16px;
        max-width: $maxWidth !important;
    }
}
.pac-icon {
    width: 11px !important;
    height: 14px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: url("../../../static/images/change-area/marker.svg") !important;
    margin-right: 8px !important;
    background-size: contain !important;
}
.pac-logo:after {
    display: none !important;
}
.pac-item,
.pac-item-query,
.pac-matched {
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.04em;
    color: $main-blue-color;
    margin: 0;
    margin-bottom: 24px;
    border: none;
    white-space: normal !important;
}
.previous-results__icon {
    width: 11px;
    height: 14px;
    min-width: 11px;
    min-height: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/change-area/marker.svg");
    margin-right: 8px;
}
.previous-results__text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    .locality,
    .region,
    .country-name,
    .postal-code {
        color: #cacad8;
        font-size: 14px;
    }
}
.previous-results__container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
