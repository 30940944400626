.change-area__container {
    margin-top: 25px;
    height: 38px;
    display: flex;
    align-items: flex-start;

    .text_secondary {
        margin-bottom: 5px;
    }
}
.change-area__icon {
    min-width: 11px;
    min-height: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/change-area/marker.svg");
    margin-right: 8px;
}
