@font-face {
    font-family: "Inter";
    src: url("../static/fonts/Inter/static/Inter-Regular-slnt=0.ttf");
}
@font-face {
    font-family: "Barlow Semi Condensed";
    src: url("../static/fonts/Inter/static/Inter-SemiBold-slnt=0.ttf");
}

//variables
$main-blue-color: #25245b;
$light-gray-color: #F2F4F5;
$dark-gray-color: #9291ad;
$dark-blue-color: #0E3C60;
$white-color: #ffffff;
$bright-blue: #28a6df;
$bright-red: #ff3b30;
$pale-red: #ff6262;
$bright-green: #0FA842;
$pale-green: #8EDFA0;
$black: #000000;

$maxWidth: 678px;

@supports (padding: unquote("max(0px)")) {
    body {
        padding: 0;
        padding-left: unquote("min(0vmin, env(safe-area-inset-left))");
        padding-right: unquote("min(0vmin, env(safe-area-inset-right))");
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    input,
    textarea {
        transform: translateZ(0px) !important;
    }
}
//reset default styles
* {
    margin: 0;
    text-decoration: none;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border: none;
    outline: none;
}
#root * {
    max-width: $maxWidth;
}
a {
    text-decoration: none;
    display: block;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &:active,
    &:visited {
        text-decoration: none;
    }
}
input,
textarea {
    //this overrides input color-> -webkit-text-fill-color: $main-blue-color;
    font-family: Inter;
    box-sizing: border-box;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    &:focus {
        outline: none;
    }
}
input[type="search"]::-webkit-search-cancel-button {
    display: none;
}
@media (min-width: $maxWidth) {
    #map-view,
    .map__container,
    .report__map,
    .location-of-incident__selection,
    .tab-bar__container,
    .report-navigation {
        margin-left: auto !important;
        margin-right: auto !important;
        left: 0 !important;
        right: 0 !important;
    }
    .sublocation-picker__container {
        width: 40% !important;
    }
    .chat__input__container {
        position: relative !important;
        margin: auto !important;
    }
}

//default styles
body {
    position: relative !important;
    position: inherit !important;
    align-items: center;
    letter-spacing: -0.02em;
    color: $main-blue-color;
    min-height: 100vh !important;
    min-height: unset !important;
    height: 0;
    word-break: break-word;
    max-width: $maxWidth;
    margin: auto;
    overscroll-behavior: none;
}
#root {
    width: 100%;
    min-height: 100%;
    max-width: $maxWidth;
    z-index: -100;
}
.container {
    padding: 16px;
    flex-direction: column;
}
.container_bordered {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(37, 36, 91, 0.1);
    padding: 18px 0 16px;
    cursor: pointer;

    &:first-child {
        padding-top: 0;
    }
}
.text_default,
.text_default_gray {
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: $main-blue-color;
}
.text_default_gray {
    color: $dark-gray-color;
}
.text_primary {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: $main-blue-color;
}
.text_enlarged {
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    color: $main-blue-color;
}
.text_secondary {
    font-weight: normal;
    font-size: 12px;
    line-height: 112.5%;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: $dark-gray-color;
    white-space: pre;
}
.text_bold {
    font-weight: 600;
}
.image {
    background-position: center;
    background-repeat: no-repeat;
}
.input {
    height: 100%;
    flex: 1;
    width: 100%;
    background-color: $white-color;

    &::placeholder {
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.04em;
        color: $dark-gray-color;
    }
}
.input__container,
.input__container_static {
    padding: 0 16px;
    background-color: $white-color;
    box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    cursor: pointer;
}
.input__container_static,
.input__container_static_gray {
    padding: 10px 16px;
    min-height: 40px;
    height: auto;
    white-space: inherit;
}
.input__container_static_gray {
    background-color: $light-gray-color;
}
.textarea {
    height: 160px;
    padding: 10px 15px;
    box-shadow: 0px 0px 30px rgba(37, 36, 91, 0.1);
}
.section__header {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: $dark-gray-color;
    margin-top: 10px;
    margin-bottom: 16px;
}
.tab-bar-predecessor {
    padding-bottom: 70px;
    overflow-y: auto;
}
.input__icon_search {
    min-width: 14px;
    min-height: 14px;
    background-image: url("../static/images/search-input-icon.svg");
    margin-right: 15px;
}
.button,
.button_bordered,
.button_bordered_small {
    background: #3bb0e5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: $white-color;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}
.button_disabled {
    opacity: 0.4;
}
.button_bordered,
.button_bordered_small {
    color: $bright-blue;
    background: $white-color;
    border: 1px solid $bright-blue;
    padding: 17px;
    height: 54px;
}
.button_bordered_small {
    height: 40px;
}
.link {
    color: $bright-blue;
    cursor: pointer;
}
.link-inline {
    display: inline;
    color: $bright-blue;
    cursor: pointer;
}
.item_disabled {
    color: rgba(255, 98, 98, 0.5) !important;
}
.warning {
    color: $bright-red;
    font-weight: 600;
    display: inline !important;
}
.background_red {
    background: $pale-red;
    color: $white-color;
}
.grecaptcha-badge { 
    visibility: hidden;
}
.display-none {
    display: none !important;
}