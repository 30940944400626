.picker__button__container {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #acacac;
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}
.picker__button {
    color: #007aff;
    padding: 0 1em;
    cursor: pointer;
}
.picker__container {
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: auto;
    margin: auto;
    transform: translate(-50%, -50%);
    background: $white-color;
    border-radius: 14px;
}
.picker__items,
.picker__items_no-scroll {
    position: relative;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 90%;
    margin: auto;
    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
.picker__items_no-scroll {
    overflow: hidden;
}
.picker__item,
.picker__item_selected,
.picker__item_neighbour,
.picker__item_disabled {
    min-height: 30px;
    white-space: nowrap;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 133%;
    color: rgba(37, 36, 91, 0.3);
    text-align: center;
    letter-spacing: -0.02em;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.theme_dark {
        color: rgba(37, 36, 91, 0.4);
    }
}
.picker__item_neighbour {
    color: rgba(37, 36, 91, 0.4);
    &.theme_dark {
        color: rgba(37, 36, 91, 0.65);
    }
}
.picker__item_selected {
    color: rgba(37, 36, 91, 0.65);
    &.theme_dark {
        font-weight: 600;
        color: $main-blue-color;
    }
}
.picker__button_hide {
    justify-content: center;
    margin: 8px auto 30px;
    cursor: pointer;
}
.picker__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 133%;
    text-align: center;
    letter-spacing: -0.02em;
    color: $main-blue-color;
    margin-bottom: 12px;
}