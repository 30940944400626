.section__header.short-form {
    margin-bottom: 20px;
}
.contact-info-control__selector {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    background: $light-gray-color;
    margin-bottom: 32px;
}
.contact-info-control__selector__item,
.contact-info-control__selector__item_active {
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.contact-info-control__selector__item_active {
    background: #CACAD8;
}
.contact-info-control .input__container {
    margin-bottom: 12px !important;
    box-shadow: 0px 0px 15px rgba(51, 50, 108, 0.1);
}