.report__item-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.report__archive-button {
    position: absolute;
    right: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 95%;
    height: 90%;
    margin-right: 1vh;
    background: $bright-blue;
    cursor: pointer;

    .report__unarchive-button__logo,
    .report__archive-button__logo {
        width: 40px;
        height: auto;
        margin: 0 2vh;
    }

    .report__unarchive-button__logo {
        width: 50px;
    }
}

.report__item {
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    padding: 8px 0;
    width: 100%;
    height: 100%;
    min-height: 50px;
    background-color: $white-color;
    transition: .3s;
    cursor: pointer;

    .report__wrapper__title {
        width: 60% !important;
        margin: 0 auto 0 2vh;

        .report__title,
        .report__last-answer {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }
        .report__title {
            font-size: 16px;
            color: $main-blue-color;
        }
        .report__last-answer {
            font-size: 14px;
            color: $dark-gray-color;
        }
    }
    .report__location-logo {
        display: flex;
        justify-content: center;
        align-items: center;

        .report__location-logo__icon {
            align-self: flex-end;
            min-width: 30px;
            min-height: 30px;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            background-color: $dark-gray-color;
            border: none;
            border-radius: 50%;
        }
    }
    
    .report__wrapper__unreaded-messages-count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-right: 2vh;
        font-size: 12px;
        color: $white-color;
        background-color: $bright-blue;
        border-radius: 50%;
    }

    .report__time {
        margin-right: 2vh;
        font-size: 12px;
        color: $dark-gray-color;
    }
}