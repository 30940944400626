.settings__container {
    .container_bordered {
        justify-content: space-between;
    }
    .favorite-location-code {
        margin: 0;
        margin-left: 16px;
    }
}

.settings__container .input__container {
  margin-bottom: 12px !important;
  box-shadow: 0px 0px 15px rgba(51, 50, 108, 0.1);
}