.human-id__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    color: $white-color;
    background-color: $dark-blue-color;
    border-radius: 10px;
    cursor: pointer;

    .human-id__button__icon,
    .human-id__button__text {
        margin: 0 1vh;
    }
}