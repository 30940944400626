.include-911-popup__title_second {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.078px;
}
.include-911-popup__title {
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: $black;
    padding: 20px 0;
    border-bottom: 1px solid #b0b3cc;
    width: 100%;
}
.include-911-popup__link_call-911 {
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    padding: 10px 0;
    text-align: center;
    letter-spacing: -0.408px;
    color: $bright-red;
}
.include-911-popup__link_report {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #007aff;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #b0b3cc;
    cursor: pointer;
}
