.microphone,
.microphone__recording {
    position: absolute;
    right: 10px;
    top: 50px;
    z-index: 10;
    width: 13px;
    height: 20px;
    text-align: center;
    background-image: url("../../../static/images/voice-input.svg");
    background-size: 13px 20px;
    background-repeat: no-repeat;
}
.microphone__recording {
    background-image: url("../../../static/images/voice-input-red.svg");
}
