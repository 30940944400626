.section__header.short-form {
    margin-bottom: 20px;
}
.radio-group-control__container {
    display: flex;
    flex-wrap: wrap;
}
.radio-group-control__container .radio-group-control__answer,
.radio-group-control__container .radio-group-control__answer_selected {
    margin: 0 4px 8px 0;
    padding: 6px 14px;

    .radio-group-control__answer_icon {
        margin-left: 4px !important;
    }
}

.radio-group-control__answer_icon {
    display: none;
}
.radio-group-control__answer,
.radio-group-control__answer_selected {
    margin-bottom: 10px;
    background: $light-gray-color;
    border-radius: 10px;
    min-height: 40px;
    padding: 10px 16px;
    box-sizing: border-box;
    cursor: pointer;
}
.radio-group-control__answer_selected {
    background: #CACAD8;

    .radio-group-control__answer_icon {
        display: block;
        background-image: url("../../../../../static/images/check-mark.svg");
        width: 10px;
        min-width: 10px;
        height: 7px;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: auto;
        background-size: contain;
    }
}
