.app-store_link {
    width: 112px;
    min-height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/appstore-link.svg");
    margin: 1vh auto 0;
    background-size: contain;
    display: block;
    color: transparent;
}
.google-play_link {
    width: 114px;
    min-height: 37px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/GooglePlay-link.png");
    margin: 8vh auto 0;
    background-size: contain;
    display: block;
    color: transparent;
}
.search-nearby__icon {
    width: 20px;
    height: 20px;
    padding-right: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/blue-search.svg");
}
.report-it-now__button__icon {
    width: 20px;
    height: 16px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/report-it-now-icon-white.svg");
}
.report-it-now__button {
    height: 54px;

    .text_default {
        color: $white-color;
    }
}
.home-locations__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding: 12px 35px;
    background: $light-gray-color;
    border-radius: 10px;
}
.home-locations__title,
.home-locations__items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.home-locations__items {
    width: 100%;
    margin: 16px 0;
    justify-content: space-between;
}
.home-locations__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-locations__title__icon {
    margin-right: 8px;
}
.home-locations__title__text {
    font-size: 14px;
}
.home-locations__title__more-button {
    font-size: 12px;
    color: $bright-blue;
    cursor: pointer;
}
.home-locations__item,
.home-locations__item__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-locations__item {
    flex: 1;
    z-index: 10;
    cursor: pointer;
}
.home-locations__item__logo {
    margin: 8px 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.home-locations__item__text {
    flex-wrap: wrap;
    text-align: center;
}
.home-locations__item__title {
    font-size: 12px;
}
.home-locations__item__subtitle {
    font-size: 10px;
}
.home-locations__search-nearby {
    height: 40px;
    background: $light-gray-color;
}
.home-locations__search-nearby .search-nearby__icon {
    height: 14px;
    width: 14px;
    background-size: contain;
}
.home-locations__search-nearby .search-nearby__text {
    font-size: 12px;
}
.title_first {
    justify-content: center;
    max-width: 300px;
    margin: 5vh auto;
    text-align: center;
}
.title_second {
    justify-content: center;
    text-align: center;
}
.title_third {
    justify-content: center;
    text-align: center;
    color: $dark-gray-color;
    margin: 1vh auto;
}