.location-item {
    display: flex;
    margin: 16px 0;
    align-items: flex-start;
    cursor: pointer;

    .text_default {
        line-height: 112.5%;
    }
}
.location-item__icon {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
}
.location-item__favorite-icon {
    margin-left: 12px;
    width: 16px;
    height: 16px;
}
.location-item__text {
    flex: 1;
}
.location-item__distance {
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    text-align: right;
    letter-spacing: -0.04em;
    color: $dark-gray-color;
    min-width: 75px;
}
