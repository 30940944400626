.calendarSelectedDayClassName {
    background: rgba(41, 165, 222, 0.5) !important;
}
.calendarClassName {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 14px !important;
    box-shadow: none !important;
}
.Calendar__yearText,
.Calendar__monthArrow {
    display: none !important;
}
.Calendar__monthArrowWrapper.-right,
.Calendar__monthArrowWrapper.-left {
    transform: none !important;
    width: 9px !important;
    height: 16px !important;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-size: contain;
    background-image: url("../../../../../static/images/date-pickerright-icon.svg") !important;
}
.Calendar__monthArrowWrapper.-right {
    background-image: url("../../../../../static/images/back-icon-blue.svg") !important;
}
.Calendar__monthYearContainer {
    flex: none !important;
}
.Calendar__monthText,
.Calendar__day {
    &::after {
        display: none !important;
    }
    color: $main-blue-color !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    letter-spacing: -0.04em !important;
    word-break: normal;
}

.Calendar__sectionWrapper {
    min-height: 290px !important;
}

.date-picker__button_hide {
    justify-content: center;
    cursor: pointer;
}
.date-picker__container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    margin: auto;
    padding: 0;
    background: $white-color;
    border-radius: 14px;
    transform: translate(-50%, -50%);
}
.date-picker__button__container {
    justify-content: space-around !important;
    border: 0 !important;
    border-top: 1px solid #acacac !important;
}