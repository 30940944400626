.location-of-incident__selection {
    position: fixed;
    z-index: 14;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: $white-color;

    .section__header {
        margin-left: 16px;
    }
    .input__container {
        margin: 16px;
        width: auto;
    }
}
.location-control__icon {
    background-image: url("../../../../../static/images/location-of-incident-icon.svg");
    min-width: 14px;
    min-height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
}
#selected-location__container {
    bottom: 0;
    background: $white-color;
    box-shadow: 0px 0px 50px rgba(37, 36, 91, 0.1);
    min-height: 190px;
    padding: 24px 16px 96px 16px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text_secondary {
        text-transform: uppercase;
    }
}
.selected-location__answer {
    display: flex;
    align-items: center;
    margin: 16px 0 24px;
}
.selected-location__icon {
    min-width: 15px;
    min-height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../../static/images/markers/location-of-incident-small.svg");
    margin-right: 10px;
}
.pac-container {
    &.location-of-incident {
       position: absolute !important;
        width: 100% !important;
        left: unset !important;
        top: 120px !important;
        border: none;
        box-shadow: none;
        padding: 0 16px;
        max-width: $maxWidth !important;
    }
}
.location-of-incident-autocomplete__icon {
    min-width: 9px;
    min-height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../../static/images/back-icon-blue.svg");
    margin-right: 16px;
}

.location-of-incident-autocomplete__icon_close {
    min-width: 10px;
    min-height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../../static/images/remove-blue.svg");
    margin-left: 16px;
}
.location-of-incident-autocomplete {
    flex: 1;
}
