.chat-list {

    .text_primary,
    .text_default,
    .text_default_gray {
        text-align: center;
        justify-content: center;
        display: block;
    }

    .chat-list__container {
        flex-direction: column;
        padding: 1em 1em 4em 1em;
    }
}

.chat-list-control__selector {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    background: $white-color;
    color: $white-color;
    box-shadow: 0px 0px 30px rgba(37, 36, 91, 0.1);
    margin: 5vh 2vh;
    cursor: pointer;
}
.chat-list-control__selector__item,
.chat-list-control__selector__item_active {
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.chat-list-control__selector__item {
    background: $bright-blue;
}
.chat-list-control__selector__item_active {
    background: $white-color;
    color: $main-blue-color;
}