.time__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 15%;
}
.semicolon {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 133%;
    text-align: center;
    letter-spacing: -0.02em;
}
.format {
    height: 60px;

    .item,
    .item-selected {
        font-size: 20px;
        height: auto;
    }
}
.divider {
    background: #cacad8;
    border-radius: 2px;
    width: 31px;
    height: 1px;
    margin: 6px auto;
}
.time-picker__button_hide {
    margin: 8px auto 48px;
    justify-content: center;
    cursor: pointer;
}
.time-picker__container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    margin: auto;
    padding: 0;
    background: $white-color;
    border-radius: 14px;
    transform: translate(-50%, -50%);
}
.time-picker__button__container {
    justify-content: space-around !important;
    border: 0 !important;
    border-top: 1px solid #acacac !important;
}
.hour-format,
.hour-format_selected {
    font-size: 20px;
    line-height: 133%;
    text-align: center;
    letter-spacing: -0.02em;
    color: $dark-gray-color;
}
.hour-format_selected {
    color: $main-blue-color;
}