.call_emergency__container {
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.01);
    border: 2px solid #ff5959;
    box-sizing: border-box;
    border-radius: 18px;
    margin: 20vh auto 0;
    display: block;
}
.call_emergency__icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/Phone.svg");
    margin: 75px auto 15px;
}
.call_emergency__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: #ff5959;
    margin: auto;
    text-align: center;
}
