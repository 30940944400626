.accept-terms-button {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.04em;
    text-decoration-line: underline;
    color: #29a5de;
    padding-top: 20px;
    width: 100%;
    background-color: $white-color;
    flex: 1;
}
.accept__container {
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
