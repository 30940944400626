.languages__item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
}
.languages__item__image {
    width: 25px;
    height: 25px;
    margin-right: 16px;
}
.languages__item__name {
    line-height: 150%;
    letter-spacing: -0.03em;
}
