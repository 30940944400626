.progress-bar__container {
    height: 24px;
    width: 100%;
    background-color: $light-gray-color;
    border-radius: 50px;
}

.progress-bar__filters {
    height: 100%;
    background-color: $bright-blue;
    border-radius: inherit;
    text-align: right;
    transition: width 1s ease-in-out;
}

.progress-bar__label {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 3px 10px;
    color: $white-color;
}