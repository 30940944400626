.map__container {
    position: absolute;
    z-index: -10;
    left: 0;
    top: 0;
    bottom: 177px;
    overflow: hidden;
    width: 100%;
}
.current-location__button {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../static/images/markers/get-current-location.svg");
    position: absolute;
    z-index: 20;
    bottom: 50px;
    border-radius: 20px;
    right: 16px;
    cursor: pointer;
}
