.report-messages-alert__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 16px 0;
    box-sizing: border-box;
    overflow: auto;

    .text_default,
    .text_default_gray {
        text-align: center;
        justify-content: center;
        display: block;
    }
    a.link {
        display: inline;
        cursor: pointer;
    }

    .google-play_link,
    .app-store_link {
        margin: 1vh;
    }

    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 8vh 0;

        > div,
        .report-messages-alert__messages-button {
            margin-bottom: 2vh;
        }

        .creds {
            .text_enlarged {
                justify-content: center;
                white-space: pre;
            }
        }
    }
}

.report-messages-alert__logo {
    min-width: 159px;
    min-height: 46px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../static/images/Logo.svg");
    background-size: contain;
}

.report-messages-alert__download-apps-icons__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}