$dark-gray-color: #9291ad;

.react-tel-input.phone-input-with-country-code {
    .form-control {
        padding: 18.5px 14px 18.5px 60px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
        -moz-box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
        box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
        border: none;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-align-items: center;
        -moz-box-align: center;
        align-items: center;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
        cursor: pointer;
        outline: none;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #25245b;
    }
    .form-control:focus {
        border: none;
        -webkit-box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
        -moz-box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
        box-shadow: 0px 0px 30px rgba(51, 50, 108, 0.1);
    }
    .selected-flag:focus:before,
    .selected-flag:focus, 
    .selected-flag.open:before {
        border: none !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    .form-control::placeholder {
        color: $dark-gray-color !important;
    }
}

.react-tel-input .form-control.invalid-number {
    border: 1px solid #ff6262 !important;
}