.section__header.short-form {
    margin-bottom: 20px;
}
.text-area-control {
    position: relative;
}
.text-area-control__short-form {
    margin-top: 20px;
}
#text-area {
    height: 120px !important;
    padding-right: 30px;
    white-space: break-spaces;
}
