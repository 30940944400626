.contact-us__warning {
    line-height: 160%;
    letter-spacing: -0.02em;
    color: $main-blue-color;
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
}
.contact-us__warning__icon {
    background-image: url("../../../static/images/i-sign.svg");
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 9px;
}
