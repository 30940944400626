.rating-bar {
    background: #cdcdcd;
    height: 4px;
    width: 100%;
    display: flex;
    margin: 28px auto;
    cursor: pointer;
}
.rating-bar_empty {
    position: relative;
    width: 10%;
}
.rating-bar_small {
    background: #ffa2a2;
    position: relative;
    width: 10%;
}
.rating-bar_middle {
    background: #ffc484;
    position: relative;
    width: 10%;
}
.rating-bar_high {
    background: #a0df9e;
    position: relative;
    width: 10%;
}
.rating-bar__point {
    position: absolute;
    top: -12px;
    right: 0;
    text-align: center;
    font-size: 14px;
}
.rating-bar__point__icon {
    background: $white-color;
    box-shadow: 0px 0px 23px rgba(37, 36, 91, 0.11);
    width: 28px;
    height: 28px;
    border-radius: 50%;
}
