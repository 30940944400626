.options__icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
}
.options__icon_settings {
    background-image: url("../../../static/images/options-settings.svg");
}
.options__icon_about {
    background-image: url("../../../static/images/options-about.svg");
}
.options__icon_website {
    background-image: url("../../../static/images/options-website.svg");
}
.options__icon_contact {
    background-image: url("../../../static/images/options-contact.svg");
}
.options__icon_terms {
    background-image: url("../../../static/images/options-terms.svg");
}
.options__icon_human-id {
    background-image: url("../../../static/images/anonymous-login-blue-icon.svg");
    background-size: contain;
}