.tab-bar__icon {
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
}

.tab-bar__icon_dots {
    background-image: url("../../../static/images/TabBar/Dots.svg");
}

.tab-bar__icon_dots_active {
    background-image: url("../../../static/images/TabBar/Dots\ Active.svg");
}

.tab-bar__icon_home {
    background-image: url("../../../static/images/TabBar/Home\ icon.svg");
}

.tab-bar__icon_home_active {
    background-image: url("../../../static/images/TabBar/Home\ icon\ active.svg");
}

.tab-bar__icon_search {
    background-image: url("../../../static/images/TabBar/Search\ icon.svg");
}

.tab-bar__icon_search_active {
    background-image: url("../../../static/images/TabBar/Search\ icon\ active.svg");
}

.tab-bar__icon_messages {
    background-image: url("../../../static/images/TabBar/Messages\ icon.svg");
}

.tab-bar__icon_messages_active {
    background-image: url("../../../static/images/TabBar/Messages\ icon\ active.svg");
}

.tab-bar__icon_favorite-location {
    width: 35px;
    height: 35px;
    padding: 15px;
    border-radius: 50%;
}

.tab-bar__container {
    display: flex;
    justify-content: space-around;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 0px 50px rgba(37, 36, 91, 0.1);
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: $white-color;
    max-width: $maxWidth;
    margin: auto;
    align-items: center;
    z-index: 100;
}
.tab-bar__icon_call_emergency {
    color: $pale-red;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    font-family: Barlow Semi Condensed;
}
.tab-bar__icon_call_emergency__first {
    font-size: 8px;
    line-height: 8px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0em;
}
.tab-bar__icon_call_emergency__second {
    font-size: 22px;
    line-height: 25px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0em;
}
