.media-choice__title {
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: $black;
    padding: 20px 0;
    border-bottom: 1px solid #b0b3cc;
    width: 100%;
}
.media-choice__item,
.media-choice__item_last {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    width: 100%;
    padding: 5px 0;
    color: #000;
}
.media-choice__item_last {
    border: none;
}
